import { snakeCase } from 'lodash'; // 🤢

import {
  I$WWrapper,
  IControllerConfig,
  IPlatformAPI,
  IWidgetControllerConfig,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import Api, {
  APP_TOAST_EVENT,
  AppToastTypes,
  Group,
  GroupList,
  isNotMember,
  isProfilePublic,
  isUserSiteAdmin,
  SiteMemberPrivacyStatus,
} from '@wix/social-groups-api';

import Experiments, { ExperimentsBag } from '@wix/wix-experiments';

import { BaseWidgetController } from '../BaseWidgetController';
import {
  GroupsWidgetActions,
  GroupsWidgetProps,
} from '../../types/groups-list/types';
import { GroupsStorage } from '../../storage/GroupsStorage';
import { UpdateProgress } from '../../ContentEditor/UpdateProgress';
import { getGroupUrl, getTabForGroup, Tab } from '../group-url';
import { ConsoleLogger } from '../../loggers';
import { WixSiteMemberActions } from '../../types/WixSiteMembers';
import { COMPONENT_ID } from '../../utils/utils';
import getBaseUrl from '../../utils/baseUrl';
import { ControllerParams } from '@wix/yoshi-flow-editor';

const { MEMBERS_WITH_MY_APPROVAL } = ApiTypes.v1.CreateGroupsPolicy;

export abstract class BaseGroupsController<
  T extends GroupsWidgetProps,
> extends BaseWidgetController<T> {
  protected readonly wixCodeApi: IWixAPI;
  protected readonly platformAPIs: IPlatformAPI;
  protected api!: Api;
  protected groupModel!: Group;
  protected groupListModel!: GroupList;
  private waitingJoinGroup!: ApiTypes.v1.GroupResponse | null;
  protected config: IControllerConfig;
  private readonly storage: GroupsStorage;
  private groupsInstanceSettings:
    | ApiTypes.v1.GroupsInstanceSettings
    | undefined;

  constructor(controllerContext: ControllerParams) {
    super(controllerContext);
    const { wixCodeApi, platformAPIs, config } = this.controllerConfig;
    this.platformAPIs = platformAPIs;
    this.wixCodeApi = wixCodeApi;
    this.config = config;
    this.initExternalServices(this.getSiteToken()!);
    this.storage = GroupsStorage.fromControllerConfig(this.controllerConfig);
    this.onUserLogin(async () => {
      this.setState({
        updateProgress: UpdateProgress.UPDATING,
        currentSiteMember: null,
      } as any);
      this.initExternalServices(this.getSiteToken() as any);
      if (this.waitingJoinGroup) {
        await this.handleGroupCardCTA(this.waitingJoinGroup);
      } else {
        await this.setGroups(this.config);
      }
    });
  }

  updateConfig($w: any, updatedConfig: IControllerConfig) {
    this.config = updatedConfig;
    return this.setGroups(updatedConfig);
  }

  protected async setGroups(config: IControllerConfig) {
    let groups: ApiTypes.v1.GroupResponse[] = [];
    let groupUrls = {};
    try {
      groups = await this.getGroups(config);
      this.cacheGroups(groups);
      groupUrls = await this.getGroupUrls(groups);
    } catch (e) {
      console.log('FAILED to get groups');
      this.errorLogger.log(e);
    }
    this.setState({
      groups,
      groupUrls,
      updateProgress: UpdateProgress.STALE,
    } as any as Partial<T>);
  }

  public goToGroup = async (groupId: string) => {
    this.setState({ navigatingToGroup: groupId } as any as Partial<T>);
    if (this.isEditorMode()) {
      this.navigateInEditor(groupId);
    } else {
      const groupToNavigate = this.groupListModel?.getGroupById(groupId);
      let tabName = Tab.DISCUSSION;

      if (groupToNavigate) {
        this.cacheGroup(groupToNavigate);
        groupId = groupToNavigate.slug || '';
        tabName = getTabForGroup(groupToNavigate);
      }
      const location = this.getLocation();
      const siteApi = this.getSiteApis();
      const groupUrl = await getGroupUrl(location, siteApi, {
        groupId,
        tabName,
      });
      // Implementation note:
      // We are using location.to with argument pageURL#<corvid-element-id>,
      // where <corvid-element-id> is  an invalid id. So it would fallback to the same scroll position without a scroll to top.
      // @ts-expect-error
      location.to(`${groupUrl}#preventScrollToTop`);
    }
  };

  private async getGroupSectionUrl() {
    const prefix = await this.getGroupPagePrefix();
    const baseUrl = this.wixCodeApi.location.baseUrl;
    return `${baseUrl}${prefix}`;
  }

  buildEditorUrl = (relativeUrl: string, options: any) => {
    let url = `${relativeUrl}`;

    if (options.queryParams !== undefined) {
      url += `?appSectionParams=${JSON.stringify(options.queryParams)}`;
    }

    return url;
  };

  private async navigateInEditor(groupId: string) {
    if (
      typeof window === 'undefined' &&
      this.controllerConfig.wixCodeApi.location.navigateToSection === undefined
    ) {
      const section = {
        sectionId: 'group',
        appDefinitionId: this.getAppDefinitionId(),
      };
      const { relativeUrl } =
        await this.controllerConfig.wixCodeApi.site.getSectionUrl(section);
      const options = { queryParams: { groupId } };

      return this.controllerConfig.wixCodeApi.location.to!(
        this.buildEditorUrl(relativeUrl!, options),
      );
    }

    const { compId } = this.controllerConfig;
    const section = {
      sectionId: 'group',
      noTransition: true,
      state: '',
      queryParams: { groupId },
      compId,
    };
    if (typeof window === 'undefined') {
      return this.controllerConfig.wixCodeApi.location.navigateToSection(
        section,
      );
    }
    return window.Wix.Utils.navigateToSection(section, console.error);
  }

  private cacheGroup(group: ApiTypes.v1.GroupResponse) {
    this.storage.setGroup(group);
  }

  protected cacheGroups(groups: ApiTypes.v1.GroupResponse[]) {
    groups.forEach((g) => this.cacheGroup(g));
  }

  public createGroup = async (
    details: ApiTypes.v1.GroupDetails,
    settings: ApiTypes.v1.GroupSettings,
    image?: File,
  ) => {
    this.setState({
      isGroupCreating: true,
    } as any as T);
    try {
      const group = await this.groupModel?.create(details, settings, image);
      this.setState({
        groups: [...(this.state.groups || []), group],
      } as any as T);

      if (group) {
        this.cacheGroup(group);
        group.slug && (await this.goToGroup(group.slug));
      }

      if (
        this.state.createGroupPolicy === MEMBERS_WITH_MY_APPROVAL &&
        !isUserSiteAdmin(this.getCurrentUser())
      ) {
        this.platformAPIs.pubSub.publish(
          APP_TOAST_EVENT,
          {
            type: AppToastTypes.GROUP_CREATED_IN_PENDING_STATE,
            options: {
              'group-name': group?.details?.title,
            },
          },
          false,
        );
        return;
      }

      this.platformAPIs.pubSub.publish(
        APP_TOAST_EVENT,
        {
          type: AppToastTypes.GROUP_CREATED,
          options: {
            'group-name': group?.details?.title,
          },
        },
        false,
      );
    } catch (e) {
      console.error(e);
      this.errorLogger.log(e);
    } finally {
      this.setState({
        isGroupCreating: false,
      } as any as T);
    }
  };

  public async getGroupsInstanceSettings(): Promise<ApiTypes.v1.GroupsInstanceSettings> {
    if (!this.groupsInstanceSettings) {
      this.groupsInstanceSettings = await this.fetchGroupsInstanceSettings();
    }
    return this.groupsInstanceSettings;
  }

  private async fetchGroupsInstanceSettings(): Promise<ApiTypes.v1.GroupsInstanceSettings> {
    try {
      const { data } =
        (await this.api?.getGroupInstanceSettings()) || ({} as any);
      return data.settings as any;
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
      return {};
    }
  }

  protected async updateGroupInstanceSettings(
    settings?: ApiTypes.v1.GroupsInstanceSettings,
  ): Promise<ApiTypes.v1.GroupsInstanceSettings> {
    const paths = Object.keys(settings || {}).map((key) => {
      return `settings.${snakeCase(key)}`;
    });
    const requestData: any = {
      fieldMask: {
        paths,
      },
      settings,
    };
    const { data } =
      (await this.api?.updateGroupInstanceSettings(requestData)) || ({} as any);
    this.groupsInstanceSettings = data.settings as any;
    return this.groupsInstanceSettings || {};
  }

  protected abstract getGroups(
    config: IControllerConfig,
  ): Promise<ApiTypes.v1.GroupResponse[]>;

  handleGroupCardCTA = (group: ApiTypes.v1.GroupResponse): Promise<void> => {
    const { relationship, slug } = group;
    if (relationship === ApiTypes.v1.RelationshipWithGroup.JOINED && slug) {
      this.waitingJoinGroup = null as any;
      return this.goToGroup(slug);
    }
    if (!this.isUserLoggedIn()) {
      this.waitingJoinGroup = group;
      return this.promptLogin();
    }
    return this.maybeChangeMembership(group);
  };
  public makeProfilePublic = async (siteMemberId: string) => {
    try {
      const {
        data: { member },
      } =
        (await this.api?.members.updateSiteMemberPrivacy(
          siteMemberId,
          SiteMemberPrivacyStatus.PUBLIC,
        )) || ({} as any);
      this.setState({
        currentSiteMember: member,
        promptPublicProfile: false,
      } as any as T);
      if (this.waitingJoinGroup) {
        await this.handleGroupCardCTA(this.waitingJoinGroup);
      }
    } catch (e) {
      console.error('Update site member profile: FAIL', e);
      this.errorLogger.log(e);
    }
  };

  async pageReady($w?: I$WWrapper, wixAPI?: IWixAPI) {
    // Props for SSR
    this.setState({ actions: this.getActions() } as any as T);
    // Props after SSR
    const currentSiteMember = await this.getCurrentSiteMember();
    const groupsProps = {
      currentSiteMember,
      navigatingToGroup: null,
      updateProgress: UpdateProgress.STALE,
    } as any as T;
    if (!this.isEditorMode()) {
      groupsProps.groupSectionUrl = await this.getGroupSectionUrl();
    }
    this.setState(groupsProps);
    return Promise.resolve();
  }

  protected getActions(): GroupsWidgetActions & WixSiteMemberActions {
    return {
      createGroup: this.createGroup,
      goToGroup: this.goToGroup,
      promptLogin: this.promptLogin.bind(this),
      handleGroupCardCTA: this.handleGroupCardCTA,
      makeProfilePublic: this.makeProfilePublic,
      submitAnswers: this.submitAnswers,
      rejectAnswers: this.rejectAnswers,
    };
  }

  promptLogin() {
    return super.promptLogin({ modal: true });
  }

  abstract getInitialProps(): Promise<Partial<T>>;

  public withdrawJoinRequest(group: ApiTypes.v1.GroupResponse) {
    // Need this because of refreshing `groupModel` in `this.initExternalServices`
    return this.groupModel?.withdrawJoinRequest(group);
  }

  public async joinGroup(group: ApiTypes.v1.GroupResponse) {
    // TODO: check for admin
    try {
      const {
        data: { questions },
      } =
        (await this.api?.members.getMembershipQuestions(group.groupId || '')) ||
        ({} as any);
      if (questions && questions.length) {
        this.waitingJoinGroup = group;
        this.setState({
          groupQuestions: { group, questions },
        } as any as T);
        return;
      }
    } catch (e) {
      console.log('Error in BaseGroupsController.joinGroup');
      this.errorLogger.log(e);
    }

    // Need this because of refreshing `groupModel` in `this.initExternalServices`
    return this.groupModel?.joinGroup(group);
  }

  private async getGroupPagePrefix() {
    if (this.isEditorIframe()) {
      return '/group';
    }
    try {
      // TODO: check this
      const sectionId = COMPONENT_ID.GROUP_PAGE;
      const { relativeUrl } = await this.getSectionUrl(sectionId);
      return relativeUrl;
    } catch (e) {
      console.error('Get Group Page prefix: FAIL');
      this.errorLogger.log(e);
    }

    return '/group';
  }

  private isEditorIframe() {
    return this.isEditorMode() && typeof window !== 'undefined';
  }

  public initExternalServices(instance: string) {
    this.api = new Api(instance, getBaseUrl(this.wixCodeApi));
    this.groupModel = new Group('', this.api, this.platformAPIs);
    this.groupListModel = new GroupList(this.api);
  }

  private async maybeChangeMembership(group: ApiTypes.v1.GroupResponse) {
    try {
      const currentSiteMember = await this.getCurrentSiteMember();
      if (currentSiteMember && !isProfilePublic(currentSiteMember)) {
        // prompt change
        this.setState({ promptPublicProfile: true } as any as T);
        this.waitingJoinGroup = group;
        return;
      }
      if (this.waitingJoinGroup !== null && group.groupId) {
        group = await this.fetchGroup(group.groupId);
        if (group && isNotMember(group as any)) {
          await this.joinGroup(group);
        }
        this.waitingJoinGroup = null as any;
        return this.setGroups(this.config);
      }
      switch (group.relationship) {
        case ApiTypes.v1.RelationshipWithGroup.PENDING_APPROVAL:
          await this.withdrawJoinRequest(group);
          break;
        case ApiTypes.v1.RelationshipWithGroup.REJECTED_MEMBERSHIP:
        case ApiTypes.v1.RelationshipWithGroup.NONE:
          await this.joinGroup(group);
          break;
        default:
          console.warn('Unknown relationship', group.relationship);
      }
    } catch (e) {
      console.log('FAILED to change membership', e);
      this.errorLogger.log(e);
    }
    return this.setGroups(this.config);
  }

  private async getCurrentSiteMember() {
    if (this.state.currentSiteMember) {
      // TODO: where is state updated?
      return Promise.resolve(this.state.currentSiteMember);
    }
    try {
      const {
        data: { member },
      } = (await this.api?.members.getCurrentSiteMember()) || ({} as any);
      return member;
    } catch (e) {
      console.error('Get current site member profile: FAIL');
      this.errorLogger.log(e);
    }
  }

  private fetchGroup(groupId: string): Promise<ApiTypes.v1.GroupResponse> {
    const group = new Group(groupId, this.api, this.platformAPIs);
    return group.fetch();
  }

  private readonly submitAnswers = (
    group: ApiTypes.v1.GroupResponse,
    answers: ApiTypes.v1.QuestionIdAnswer[],
  ) => {
    if (!answers) {
      return this.rejectAnswers();
    }
    return this.groupModel
      .joinGroup(group, answers)
      .then(() => {
        return this.setGroups(this.config);
      })
      .catch((e) => {
        console.log('Error in BaseGroupsController.submitAnswers');
        this.errorLogger.log(e);
      })
      .finally(() => {
        this.setState({ groupQuestions: null } as any as T);
        this.waitingJoinGroup = null as any;
      });
  };

  private readonly rejectAnswers = () => {
    this.waitingJoinGroup = null;
    this.setState({ groupQuestions: null } as any as T);
  };

  async getGroupUrls(
    groups: ApiTypes.v1.GroupResponse[],
  ): Promise<{ [key: string]: string }> {
    if (this.isEditorMode()) {
      // location & site apis don't work in editor! https://wix.slack.com/archives/C01A2MH2ZEC/p1615395074010500
      return {};
    }
    const location = this.getLocation();
    const siteApi = this.controllerConfig.wixCodeApi.site;
    const idUrl = await Promise.all(
      groups.map((group) => {
        const { slug, groupId } = group;
        const tabName = getTabForGroup(group);
        return Promise.all([
          groupId,
          getGroupUrl(location, siteApi, { groupId: slug, tabName } as any),
        ]);
      }),
    );

    const urls = Object.fromEntries(idUrl);
    return urls;
  }
}

import { Location, SiteApi } from './types';
import { UrlSegments } from './UrlSegments';
import { Tab } from './Tab';
import Url from 'url-parse';
import { IWixWindowViewMode } from '@wix/native-components-infra/dist/src/types/types';
import { COMPONENT_ID } from '../../utils/utils';
import { GROUPS_APP_ID } from '../../../config/constants';

export const DEFAULT_SEGMENTS = {
  section: COMPONENT_ID.GROUP_PAGE,
  groupId: undefined,
  activeTab: Tab.DISCUSSION,
};

export const GROUP_SECTION_ID = {
  sectionId: COMPONENT_ID.GROUP_PAGE,
  appDefinitionId: GROUPS_APP_ID,
};
export const GROUP_SECTION_ID_OLD = {
  sectionId: COMPONENT_ID.GROUP,
  appDefinitionId: GROUPS_APP_ID,
};

export async function getGroupSection(siteApi: SiteApi) {
  try {
    const oldGroupPage = await siteApi.isAppSectionInstalled(
      GROUP_SECTION_ID_OLD,
    );

    const { relativeUrl } = await siteApi.getSectionUrl(
      oldGroupPage ? GROUP_SECTION_ID_OLD : GROUP_SECTION_ID,
    );
    if (relativeUrl) {
      return relativeUrl.replace('/', '');
    }
  } catch (e) {
    // EditorX preview has a problem with using siteApi
    return COMPONENT_ID.GROUP_PAGE;
  }
  return COMPONENT_ID.GROUP_PAGE;
}
async function getSegmentsFromQuery(
  location: Location,
  siteApi: SiteApi,
  section: string = '',
): Promise<UrlSegments | undefined> {
  if (location.query && location.query.appSectionParams) {
    const { groupId } = JSON.parse(location.query.appSectionParams);
    return {
      activeTab: Tab.DISCUSSION,
      groupId,
      section: section || (await getGroupSection(siteApi)),
    };
  }

  return undefined;
}

function groupSegmentsFromCustomized(customizedUrlSegments: {
  key: string;
  segments: { [p: string]: string } | undefined;
}) {
  const {
    segments: { groupId, tabName, postId },
  }: any = customizedUrlSegments;
  // TODO: resolve section?
  return {
    feedItemId: postId,
    activeTab: tabName as Tab,
    groupId,
    section: '',
  };
}

async function getCustomizedUrlSegments(
  url: string,
  siteApi: SiteApi,
): Promise<
  { key: string; segments: { [p: string]: string } | undefined } | undefined
> {
  try {
    const customizedUrlSegments = await siteApi.getCustomizedUrlSegments?.(url);
    if (customizedUrlSegments) {
      return customizedUrlSegments;
    }
  } catch (e) {
    console.log('[getCustomizedUrlSegments] error');
  }
  return;
}

function getUrlSegments(location: Location): UrlSegments {
  const { url, baseUrl, path } = location;
  let segments!: UrlSegments;
  if (path[0] === COMPONENT_ID.GROUP_PAGE) {
    segments = getSegmentsFromPath(path);
  }
  if (!segments) {
    const { query, hash } = new Url(url);
    const pathname = url
      .replace(baseUrl, '')
      .replace(hash, '')
      .replace(`${query}`, '')
      .split('/')
      .filter((s) => !!s);
    segments = getSegmentsFromPath(pathname);
  }
  return segments;
}

function getSegmentsFromPath(path: string[]) {
  const [section, groupId, activeTab, feedItemId] = path;
  return {
    activeTab: (activeTab as Tab) || Tab.DISCUSSION,
    feedItemId,
    groupId,
    section,
  };
}

async function getDefaultSegments(siteApi: SiteApi): Promise<UrlSegments> {
  const segments = DEFAULT_SEGMENTS as unknown as UrlSegments;
  segments.section = await getGroupSection(siteApi);
  return segments;
}

async function getSegmentsForPreviewMode(
  location: Location,
  siteApi: SiteApi,
  section: string = '',
) {
  const { path } = location;
  let segments!: UrlSegments | undefined;
  if (path[0] === section) {
    segments = getSegmentsFromPath(path);
  }
  if (!segments) {
    segments = await getSegmentsFromQuery(location, siteApi, section);
  }
  return segments;
}

async function getSegmentsForEditor(
  location: Location,
  siteApi: SiteApi,
  viewMode?: IWixWindowViewMode,
): Promise<UrlSegments> {
  let segments: UrlSegments = await getDefaultSegments(siteApi);
  if (viewMode === 'Preview') {
    segments =
      (await getSegmentsForPreviewMode(location, siteApi, segments.section)) ||
      segments;
  }
  return segments;
}

function isEditor(viewMode: IWixWindowViewMode) {
  return viewMode === 'Preview' || viewMode === 'Editor';
}

export async function getGroupUrlSegments(
  location: Location,
  siteApi: SiteApi,
  viewMode?: IWixWindowViewMode,
): Promise<UrlSegments> {
  let segments!: UrlSegments;
  try {
    const { url, query } = location;
    if (isEditor(viewMode!)) {
      return await getSegmentsForEditor(location, siteApi, viewMode);
    }

    const custom = await getCustomizedUrlSegments(url, siteApi);
    if (custom) {
      return groupSegmentsFromCustomized(custom);
    }

    if (!segments) {
      segments = getUrlSegments(location);
      segments.section = await getGroupSection(siteApi);
    }

    segments.commentId = query && query.commentId;
    if (!segments) {
      throw new Error('[getGroupUrlSegments] No segments!');
    }
  } catch (e) {
    console.log(`[getGroupUrlSegments] Error ${e?.message}`);
  }
  return segments;
}

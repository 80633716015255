import { ISettingsParam } from '@wix/yoshi-flow-editor/build/cjs/tpa-settings';
import { ImageRatio, LayoutType } from './settingsConstants';

export const WIDGET_NUM_GROUPS = 3;
export const WIDGET_MAX_NUM_GROUPS = 9;

export type IWidgetSettingsParams = {
  imageRatio: ISettingsParam<ImageRatio>;
  groupListLayout: ISettingsParam<LayoutType>;
  gridCardSpacing: ISettingsParam<number>;
  numberGroups: ISettingsParam<number>;

  showGroupType: ISettingsParam<boolean>;
  showActivity: ISettingsParam<boolean>;
  showButton: ISettingsParam<boolean>;
};

export const widgetSettingsParams: IWidgetSettingsParams = {
  /**
   * Widget Layout settings
   */
  numberGroups: {
    getDefaultValue: () => WIDGET_NUM_GROUPS,
    key: 'numberGroups',
  },
  imageRatio: {
    getDefaultValue: () => ImageRatio.square,
    key: 'imageRatio',
  },
  groupListLayout: {
    getDefaultValue: () => LayoutType.listWidget,
    key: 'groupListLayout',
  },
  gridCardSpacing: {
    getDefaultValue: () => 12,
    key: 'gridCardSpacing',
  },

  /**
   * Widget Design settings
   */
  showActivity: {
    getDefaultValue: () => true,
    key: 'showActivity',
  },
  showGroupType: {
    getDefaultValue: () => true,
    key: 'showGroupType',
  },
  showButton: {
    getDefaultValue: () => true,
    key: 'showButton',
  },
};

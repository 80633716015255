import {
  IMonitoring,
  IWidgetControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';

import { dsn, Environment } from './sentryConfig';
import { Logger, LogLevel } from './Logger';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

type LogError = ControllerFlowAPI['sentry']['captureException'];

export class ControllerErrorLogger implements Logger {
  private static instance: ControllerErrorLogger;
  private monitor!: ControllerFlowAPI['sentry'];

  mode!: LogLevel;

  private constructor() {}

  public static getInstance(
    controllerConfig: IWidgetControllerConfig,
    environment: Environment,
  ): ControllerErrorLogger {
    if (!ControllerErrorLogger.instance) {
      const monitoring = controllerConfig.platformAPIs.monitoring;
      const logger = new ControllerErrorLogger();
      logger.monitor = ControllerErrorLogger.createMonitor(
        monitoring!,
        environment,
      );
      ControllerErrorLogger.instance = logger;
    }

    return ControllerErrorLogger.instance;
  }

  log: LogError = (ex, options = {}) => {
    return this.monitor.captureException(ex, options);
  };

  trace: LogError = (ex, options = {}) => {
    Object.assign(options, {
      stacktrace: true,
      level: 'critical',
    });
    return this.monitor.captureException(ex, options);
  };

  static createMonitor(monitoring: IMonitoring, environment: Environment) {
    return monitoring.createMonitor(dsn, (data) => {
      data.environment = environment;

      return data;
    });
  }

  static fromSentryClient(sentryClient: any) {
    if (!ControllerErrorLogger.instance) {
      const logger = new ControllerErrorLogger();
      logger.monitor = sentryClient;
      ControllerErrorLogger.instance = logger;
    }

    return ControllerErrorLogger.instance;
  }
}

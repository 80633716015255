import { IWebStorage } from '@wix/native-components-infra/dist/src/types/types';

export class BasicStorage implements IWebStorage {
  private storage: { [key: string]: any } = {};
  clear(): void {
    this.storage = {};
  }

  getItem(key: string): string | null {
    return this.storage[key];
  }

  removeItem(key: string): void {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete this.storage[key];
  }

  setItem(key: string, value: string): void {
    this.storage[key] = value;
  }
}

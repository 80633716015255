
  import userController from '/home/builduser/agent00/work/e95c89e2f004c2bf/social-groups/social-groups-ui/packages/social-groups-ooi/src/components/GroupsListWidget/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/controllerWrapper.js';

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    

  

  import { initI18nWithoutICU as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    


  var sentryConfig = {
    DSN: 'https://ea002ae7678c4b8db9a0bb5b9175faaf@sentry.wixpress.com/1166',
    id: 'ea002ae7678c4b8db9a0bb5b9175faaf',
    projectName: 'social-groups-ooi',
    teamName: 'wix-groups',
    
  };

  var experimentsConfig = {"scopes":["specs.groups","specs.NewMembersApi","ricos"]};

  var translationsConfig = {"icuEnabled":false,"defaultTranslationsPath":"/home/builduser/agent00/work/e95c89e2f004c2bf/social-groups/social-groups-ui/packages/social-groups-ooi/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var biConfig = null;

  var defaultTranslations = {"groups-web.app.delete":"Delete Groups","groups-web.page.delete":"Delete","groups-web.page.info":"Page Info","groups-web.page.seo":"SEO Basics","groups-web.page.layout":"Layouts","groups-web.page.permissions":"Permissions","groups-web.page.info.title":"What’s a group page?","groups-web.page.info.p1":"This page opens when someone clicks on a group from the group list. Depending on the group’s privacy settings, the page displays the group's info, discussions, members and media.","groups-web.page.info.title2":"How does this page behave?","groups-web.page.info.p2":"This is a template page. Any changes you make will affect all group pages. You can manage group specific settings in the","groups-web.page.info.link":"Groups Dashboard","groups-web.manage.actions.main":"Go to Dashboard","groups-web.manage.actions.create.group":"Create New Group","groups-web.manage.actions.setup":"Set up the Group","groups-web.manage.actions.add.widget":"Add Group Widget","groups-web.settings.addons":"Add-Ons"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/agent00/work/e95c89e2f004c2bf/social-groups/social-groups-ui/packages/social-groups-ooi/target/generated/bi/createBILogger.ts';

  const _controller = createControllerWrapper(userController, {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    appName: "Wix Groups",
    componentName: "GroupsListWidget",
    appDefinitionId: "148c2287-c669-d849-d153-463c7486a694",
    componentId: "0a9f687f-7e00-4576-a8e1-9415844b8f44",
    projectName: "social-groups-ooi",
    defaultTranslations,
    shouldUseEssentials: false,
    withErrorBoundary: false,
    localeDistPath: "components/GroupsListWidget/locales"
  }, {
    initI18n,
    createHttpClient,
    createExperiments,
  });
  export const controller = _controller;
  export default _controller;

import {
  IFedOpsLogger,
  IWidgetControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';

export class FedopsLogger {
  static fromControllerConfig(
    controllerConfig: IWidgetControllerConfig,
  ): IFedOpsLogger {
    const fedopsLogger = controllerConfig.platformAPIs.fedOpsLoggerFactory;
    const appId = controllerConfig.appParams.appDefinitionId;
    const widgetId = controllerConfig.type;
    return fedopsLogger!.getLoggerForWidget({ appId, widgetId });
  }
}
